export type Choice =
    | 'energi'
    | 'digital'
    | 'begge'
    | 'b2b-energi'
    | 'b2b-digital'
    | 'partner' // henvist.norlys.dk
    | 'partner-digital' // henvist.norlys.dk
    | 'ladestander';

export enum TermsIds {
    'energi' = '9b55a8bc-ed8f-46eb-a532-9a1c967cd010',
    'digital' = 'cffda206-cf9d-46b5-885a-ddcc25744aff',
    'begge' = 'e8c30207-1972-43f1-b15e-e4144409df65',
    'b2b-energi' = '69916f63-fbfc-4be9-9c9e-2e691e5161a0',
    'b2b-digital' = '17912474-d2aa-4024-89dd-86d1afcd96d4',
    'partner' = '9b55a8bc-ed8f-46eb-a532-9a1c967cd010',
    'partner-digital' = 'cffda206-cf9d-46b5-885a-ddcc25744aff', // TODO: Change to correct id if not same as digital
    'ladestander' = 'bd632e45-202f-49cb-9c49-2208a5d73f8c',
}
