import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import FormScreen from './screens/FormScreen';
import ThankYouScreen from './screens/ThankYouScreen';

const App = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<FormScreen />} />
                <Route path="/thank-you" element={<ThankYouScreen />} />
                <Route path="*" element={<Navigate to="/" />} />
            </Routes>
        </BrowserRouter>
    );
};

export default App;
