import * as Yup from 'yup';
import { Choice } from './Choice';

export const validationSchema = Yup.object().shape({
    name: Yup.string().required('Navn skal udfyldes'),
    companyName: Yup.string().when('choice', {
        is: (value: Choice) => {
            return value === 'b2b-energi' || value === 'b2b-digital';
        },
        then: Yup.string().required('Firmanavn skal udfyldes'),
        otherwise: Yup.string().optional(),
    }),
    cvr: Yup.string(),
    dawa_address: Yup.string().required('En gyldig adresse skal angives'),
    currentServiceProvider: Yup.string().optional(),
    phone: Yup.string().required('Telefonnr. skal udfyldes'),
    email: Yup.string().required('Email skal udfyldes'),
    notes: Yup.string().when('choice', {
        is: (value: Choice) => {
            return (
                window.location.href.includes('henvist') || // make sure user sees the error message even if they haven't selected a choice
                value === 'partner' ||
                value === 'partner-digital'
            );
        },
        then: Yup.string().required('Noter skal udfyldes'),
        otherwise: Yup.string().optional(),
    }),
    employeeInitials: Yup.string().when('choice', {
        is: (value: Choice) => {
            return (
                value === 'energi' ||
                value === 'digital' ||
                value === 'begge' ||
                value === 'b2b-energi' ||
                value === 'b2b-digital'
            );
        },
        then: Yup.string().required('Medarbejderinitialer skal udfyldes'),
        otherwise: Yup.string().optional(),
    }),
    department: Yup.string().when('choice', {
        is: (value: Choice) => {
            return value === 'b2b-energi' || value === 'b2b-digital';
        },
        then: Yup.string().required('Afdeling skal vælges'),
        otherwise: Yup.string().optional(),
    }),
    code: Yup.string().when('choice', {
        is: (value: Choice) => {
            return value === 'partner' || value === 'partner-digital';
        },
        then: Yup.string().required('Medarbejderinitialer skal udfyldes'),
        otherwise: Yup.string().optional(),
    }),
    accept: Yup.bool().oneOf([true], 'Betingelserne skal accepteres'),
    choice: Yup.string().required('Valg skal angives'),
});
