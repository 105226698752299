import React from 'react';
import { useFormContext } from 'react-hook-form';
import { FormFields } from './LeadForm/FormFields';

const Input = ({
    label,
    name,
    type = 'text',
}: {
    label: string;
    name: keyof FormFields;
    type?: string;
}) => {
    const { register, formState } = useFormContext<FormFields>();

    return (
        <label className="block relative text-grey-60 input-group">
            <textarea
                rows={3}
                className="w-full h-full rounded-md bg-white p-24 text-grey-100 placeholder-grey-60 ring-2 ring-border-grey focus:ring-green-strong border-2 border-transparent"
                placeholder={label}
                {...register(name)}
            />

            <span className="block text-red-strong mt-8 ml-8">
                {formState.errors[name]?.message}
            </span>
        </label>
    );
};

export default Input;
