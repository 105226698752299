import React, { useEffect, useRef } from 'react';
// @ts-ignore
import * as dawaAutocomplete2 from 'dawa-autocomplete2';
import { useFormContext } from 'react-hook-form';
import {DawaAddress, DawaAddressAutocompleteSelection} from '../service/dawa';
import { FormFields } from './LeadForm/FormFields';

const AddressInput = ({
    label,
    name,
    onAddress,
}: {
    label: string;
    name: keyof FormFields;
    onAddress: (address: DawaAddress) => void;
}) => {
    const { setValue, formState, register } = useFormContext<FormFields>();
    const { ref, ...rest } = register(name);
    const inputElementRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        inputElementRef.current!.value = '';
        dawaAutocomplete2.dawaAutocomplete(inputElementRef.current, {
            select(selectedAddress: DawaAddressAutocompleteSelection) {
                setValue(name, selectedAddress.tekst);
                onAddress(selectedAddress.data);
            },
        });
    }, [name, onAddress, setValue]);

    return (
        <label className="block relative text-grey-60 input-group">
            <input
                type="text"
                placeholder=" "
                className="dawa-autocomplete-container w-full h-full rounded-md bg-white p-24 text-grey-100 ring-2 ring-border-grey focus:ring-green-strong border-2 border-transparent"
                {...rest}
                ref={(e) => {
                    ref(e);
                    inputElementRef.current = e;
                }}
            />

            <span className="absolute top-0 left-0 p-24 input-group__floating-label transition-transform">
                {label}
            </span>

            <span className="block text-red-strong mt-8 ml-8">
                {formState.errors.dawa_address?.message}
            </span>
        </label>
    );
};

export default AddressInput;
