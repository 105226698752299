import React from 'react';
import Topbar from '../components/Topbar';

const ThankYouScreen = () => (
    <div className="bg-green-bright min-h-screen">
        <Topbar />
        <div className="container mx-auto px-16 py-40">
            <h2 className="font-serif text-xl mb-24">
                Tak for din henvendelse!
            </h2>
        </div>
    </div>
);

export default ThankYouScreen;
