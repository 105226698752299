import { useEffect, useState } from 'react';
import api from '../service/api';
import { Choice, TermsIds } from '../components/LeadForm/Choice';

const useTermsText = (choice: Choice | undefined): string | undefined => {
    const [permissionsText, setPermissionsText] = useState<string>();

    useEffect(() => {
        setPermissionsText(undefined);
        api.get<string>('getTermsTextFromRubiqCloud', {
            params: {
                TERMS_ID: TermsIds[choice || 'energi'], // Energi is the default form
                SOURCE: 'henvisning.norlys.dk',
            },
        })
            .then((text) => {
                if (text && text.data) {
                    setPermissionsText(text.data);
                } else {
                    setPermissionsText(undefined);
                }
            })
            .catch(() => {
                setPermissionsText(undefined);
            });
    }, [choice]);

    return permissionsText;
};

export default useTermsText;
