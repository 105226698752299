import React, { InputHTMLAttributes } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormFields } from './LeadForm/FormFields';

const Checkbox = (
    props: {
        label: any;
        name: keyof FormFields;
    } & InputHTMLAttributes<HTMLInputElement>
) => {
    const { register } = useFormContext<FormFields>();

    return (
        <div className="ToggleButton">
            <input {...props} {...register(props.name)} type="checkbox" />
            <label htmlFor={props.id}>
                <span
                    className="leading-snug"
                    dangerouslySetInnerHTML={{ __html: props.label }}
                />
            </label>
        </div>
    );
};

export default Checkbox;
