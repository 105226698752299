import React from 'react';
import { useNavigate } from 'react-router-dom';
import LeadForm from '../components/LeadForm';
import Topbar from '../components/Topbar';
import useSubdomain from '../hooks/useSubdomain';

const FormScreen = () => {
    const subdomain = useSubdomain();
    const navigate = useNavigate();
    return (
        <div className="bg-green-bright min-h-screen">
            <Topbar />
            <div className="container mx-auto px-16 py-40">
                <h2 className="font-serif text-xl mb-24">
                    Indtast dine oplysninger
                </h2>

                <div className="my-40 lg:w-2/3 max-w-[800px] space-y-56">
                    {subdomain === 'henvisning' ? (
                        <div>
                            Vær opmærksom på at tilbuddene ved
                            medarbejderhenvisning af venner og bekendte kun
                            tilbydes til nye kunder.
                        </div>
                    ) : null}
                    <LeadForm
                        onLeadSubmitted={() => {
                            navigate('/thank-you');
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default FormScreen;
