import React from 'react';
import {useFormContext} from 'react-hook-form';
import {FormFields} from './LeadForm/FormFields';
import classNames from "classnames";

const Input = ({
                   label,
                   name,
                   type = 'text',
                   lowercase = false,
               }: { label: string; name: keyof FormFields, type?: string, lowercase?: boolean }) => {
    const {register, formState} = useFormContext<FormFields>();

    return (
        <label className="block relative text-grey-60 input-group">
            <input
                className={classNames("w-full h-full rounded-md bg-white p-24 text-grey-100 ring-2 ring-border-grey focus:ring-green-strong border-2 border-transparent", {
                    'lowercase': lowercase
                })}
                type={type}
                placeholder=" "
                {...register(name)}
            />
            <span className="absolute top-0 left-0 p-24 input-group__floating-label transition-transform">
                {label}
            </span>

            <span className="block text-red-strong mt-8 ml-8">{formState.errors[name]?.message}</span>
        </label>
    );
};

export default Input;
