import { parse } from 'tldts';

export type Subdomain = 'henvisning' | 'henvist';

const DEFAULT_SUBDOMAIN: Subdomain = 'henvisning';

export default function useSubdomain(): Subdomain {
    const subdomain = parse(window.location.host).subdomain;
    if (subdomain === 'henvisning' || subdomain === 'henvist') {
        return subdomain;
    } else {
        return DEFAULT_SUBDOMAIN; // default
    }
}
