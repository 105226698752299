import axios from 'axios';
import { FormFields } from '../components/LeadForm/FormFields';
import { DawaAddress } from './dawa';

const api = axios.create({
    // baseURL: 'http://localhost:4000/prod',
    baseURL: 'https://on6bpy8ldj.execute-api.eu-central-1.amazonaws.com/prod',
});

export const saveLead = async (
    formFields: FormFields,
    address: DawaAddress
): Promise<void> => {
    await api.post('/savePortalLead', { formFields, address });
};

export default api;
