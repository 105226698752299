import React from 'react';
import NorlysLogo from './NorlysLogo';

const Topbar = () => (
    <div className="bg-white shadow-lg">
        <div className="container mx-auto px-16 py-40">
            <div className="h-16">
                <NorlysLogo />
            </div>
        </div>
    </div>
);

export default Topbar;
