import React, { useMemo, useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import useSubdomain from '../../hooks/useSubdomain';
import { saveLead } from '../../service/api';
import AddressInput from '../AddressInput';
import Checkbox from '../Checkbox';
import Input from '../Input';
import { Choice } from './Choice';
import { FormFields } from './FormFields';
import { validationSchema } from './ValidationSchema';
import { CampaignTemplateName } from './CampaignTemplateName';
import TextArea from '../TextArea';
import Dropdown from '../Dropdown';
import useTermsText from '../../hooks/useTermsText';
import { DawaAddress } from '../../service/dawa';

const LeadForm = ({ onLeadSubmitted }: { onLeadSubmitted: () => void }) => {
    const subdomain = useSubdomain();
    const [isSendingLead, setIsSendingLead] = useState(false);
    const [sendLeadError, setSendLeadError] = useState<Error>();

    const [address, setAddress] = useState<DawaAddress | null>(null);

    const form = useForm<FormFields>({
        defaultValues: {
            choice: undefined,
            name: '',
            dawa_address: '',
            email: '',
            phone: '',
            notes: '',
            employeeInitials: '',
            code: '',
            accept: false,
        },
        shouldUseNativeValidation: true,
        resolver: yupResolver(validationSchema),
    });

    const choice: Choice | undefined = useWatch({
        name: 'choice',
        control: form.control,
    });

    const termsText = useTermsText(choice);

    const herobaseTemplate = useMemo<CampaignTemplateName | undefined>(() => {
        switch (choice) {
            case 'energi':
                return 'Gokke';

            case 'digital':
            case 'begge':
                return 'Stofa Aarhus Outbound Vic';

            case 'b2b-energi':
            case 'b2b-digital':
                return 'Leads Til Stofa';

            case 'ladestander':
                return 'Ladestander';

            case 'partner':
                return 'TeknikkerLead';

            case 'partner-digital':
                return 'Outbound Aarhus Teknikerleads'; // Todo: if same as partner, then use same template

            default:
                return undefined;
        }
    }, [choice]);

    const isB2B = herobaseTemplate && herobaseTemplate === 'Leads Til Stofa';
    const isB2BDigital = choice && choice === 'b2b-digital';
    const isPartner =
        choice && (choice === 'partner' || choice === 'partner-digital'); // henvist.norlys.dk

    return (
        <FormProvider {...form}>
            <form
                onSubmit={form.handleSubmit((data) => {
                    setIsSendingLead(true);
                    setSendLeadError(undefined);

                    // make medarbejderInitialer lowercase before sending.
                    // This is the recommended way, instead of transforming on input: https://github.com/react-hook-form/react-hook-form/discussions/7867
                    data.employeeInitials =
                        data.employeeInitials?.toLowerCase();
                    data.code = data.code?.toLowerCase();

                    if (address) {
                        saveLead(data, address)
                            .then(onLeadSubmitted)
                            .catch((err) => {
                                setSendLeadError(
                                    new Error(err.response.data.message)
                                );
                            })
                            .finally(() => {
                                setIsSendingLead(false);
                            });
                    } else {
                        setSendLeadError(new Error('Ingen adresse valgt'));
                    }
                })}
            >
                <div className="space-y-32">
                    {subdomain === 'henvisning' ? (
                        <Dropdown
                            allowEmpty
                            id="choice"
                            name="choice"
                            label="Hvad er du interesseret i?"
                            options={[
                                { value: 'energi', label: 'El' },
                                { value: 'ladestander', label: 'Ladestander' },
                                { value: 'digital', label: 'Internet & TV' },
                                { value: 'begge', label: 'El + Internet & TV' },
                                {
                                    value: 'b2b-energi',
                                    label: 'B2B - leads til Energi',
                                },
                                {
                                    value: 'b2b-digital',
                                    label: 'B2B - leads til Digital',
                                },
                            ]}
                        />
                    ) : (
                        <Dropdown
                            allowEmpty
                            id="choice"
                            name="choice"
                            label="Hvad er du interesseret i?"
                            options={[
                                { value: 'partner-digital', label: 'Digital' },
                                { value: 'partner', label: 'Energi' },
                            ]}
                        />
                    )}

                    {isB2B ? (
                        <Input name="name" label="Kontaktperson" />
                    ) : (
                        <Input name="name" label="Kundenavn" />
                    )}

                    {isB2B ? (
                        <>
                            <Input name="companyName" label="Firmanavn" />
                            <Input name="cvr" label="CVR-nr." />
                        </>
                    ) : null}

                    <AddressInput
                        name="dawa_address"
                        label="Adresse"
                        onAddress={setAddress}
                    />

                    {isB2BDigital ? (
                        <Input
                            name="currentServiceProvider"
                            label="Nuværende udbyder"
                        />
                    ) : null}

                    <Input name="phone" label="Telefon" />

                    <Input name="email" label="Email" type="email" />

                    {!isPartner ? (
                        <>
                            <Input
                                label="Medarbejderinitialer"
                                name="employeeInitials"
                                lowercase
                            />

                            {isB2B ? (
                                <Dropdown
                                    label="Hvilken afdeling stammer leadet fra?"
                                    name="department"
                                    allowEmpty
                                    options={[
                                        {
                                            value: 'Digital Outbound',
                                            label: 'Digital Outbound',
                                        },
                                        {
                                            value: 'Digital Erhvervsservice',
                                            label: 'Digital Erhvervsservice',
                                        },
                                        {
                                            value: 'Energi Outbound',
                                            label: 'Energi Outbound',
                                        },
                                        {
                                            value: 'Energi Erhvervsservice',
                                            label: 'Energi Erhvervsservice',
                                        },
                                        {
                                            value: 'Andet',
                                            label: 'Andet',
                                        },
                                    ]}
                                />
                            ) : null}

                            <TextArea name="notes" label="Noter" />
                        </>
                    ) : null}

                    {isPartner ? (
                        <>
                            <Input
                                name="code"
                                label="Medarbejderinitialer"
                                lowercase
                            />
                            <TextArea name="notes" label="Noter" />
                        </>
                    ) : null}
                </div>

                <div className="mt-80 space-y-32">
                    <div>
                        {form.formState.errors.accept ? (
                            <div className="text-red-strong mb-16">
                                &darr; {form.formState.errors.accept.message}
                            </div>
                        ) : null}

                        {termsText && (
                            <Checkbox
                                id="accept"
                                name="accept"
                                label={termsText}
                            />
                        )}
                    </div>

                    <button
                        type="submit"
                        className="btn bg-green-dark text-white"
                        disabled={isSendingLead}
                    >
                        {isSendingLead ? 'Et øjeblik ...' : 'Send'}
                    </button>

                    {sendLeadError ? (
                        <div className="text-red-strong">
                            {sendLeadError.name} {sendLeadError.message}
                        </div>
                    ) : null}
                </div>
            </form>
        </FormProvider>
    );
};

export default LeadForm;
