import React, { InputHTMLAttributes } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormFields } from './LeadForm/FormFields';

const Dropdown = (
    props: {
        label: string;
        name: keyof FormFields;
        options: { value: string; label: string }[];
        allowEmpty?: boolean;
    } & InputHTMLAttributes<HTMLSelectElement>
) => {
    const { register, formState } = useFormContext<FormFields>();

    return (
        <div className="Select space-y-8">
            <label htmlFor={props.id} className="text-base">
                {props.label}
            </label>
            <select {...register(props.name)} id={props.id}>
                {props.allowEmpty && <option value="">Vælg ...</option>}
                {props.options.map((option, i) => (
                    <option key={i} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </select>
            <span className="block text-red-strong mt-8 ml-8">
                {formState.errors[props.name]?.message}
            </span>
        </div>
    );
};

export default Dropdown;
